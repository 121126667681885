.nav-logo-holder {
  @include media-breakpoint-down(xs) {
    .navbar-brand > svg {
      max-width: 100px;
    }

    .stateofct-brand img {
      max-width: 30px;
    }
  }

  .stateofct-brand img {
    max-width: 40px;
  }
}

.site-navigation-level-1 {
  .nav-item.active::after {
    background-color: #fefefe;
  }
}
