table.table-row-stacker.d-block.d-lg-none {
  tbody {
    display: table;
    width: 100%;

    th {
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}
